<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>CASE REST APIs</h3>

	<p>Publishing standards data using CASE requires two steps: first you must code your standards in CASE JSON format; then you must make that JSON available to the world using a standard set of APIs. </p>
	<ul>
		<li>These APIs allow any digital system to pull the CASE JSON at any time via a webserver-to-webserver connection.</li>
		<li>This allows, for example, student information systems and learning management systems to provide functionality that allows a school district to, with a click of a button, import all state standards into the district’s SIS or LMS.</li>
		<li>Educators at the school district can then, for example, tag content to those state standards in their digital system. Since content will be tagged to the official state standards’ <span v-html="link('identifiers', 'CASE identifiers (GUIDs)')"></span>, if some standards later need to be corrected, those corrected standards can be re-pulled to the digital system, and alignments will remain intact.</li>
	</ul>
	<p>As a certified CASE publishing tool, <span v-html="app_name"/> implements all the CASE REST APIs according to the CASE spec.</p>
	<ul>
		<li>The most commonly-used CASE API, the <code>getCFPackage</code> API, pulls data for all standards in a framework with a single API call.</li>
		<li>Choose “COPY CASE package API link” from the <v-icon small class="mx-1">fas fa-ellipsis-v</v-icon> <span v-html="link('kebab_menu', 'kebab menu')"></span> to copy this API’s url to your clipboard.</li>
		<li>Note that when using the <code>getCFPackage</code> API (or any other CASE API) to pull data from <span v-html="app_name"/>, you have three choices: <ul>
			<li>Use the “v1p0” version of the API to pull the official CASE 1.0 JSON for the framework. The v1p0 API URLs will have this format:<nobr><code>https://example.edu/ims/case/<b>v1p0</b>/CFPackages/XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX</code></nobr></li>
			<li>Use the “v1p1” version of the API to pull a version of the CASE JSON that also includes new fields introduced as part of CASE version 1.1. If you use the v1p1 API, the JSON will also include, in the “extensions” child of CFItems, CFAssociations, and CFDocuments, fields that are coded in <span v-html="app_name"/>, but aren’t (yet) part of the official CASE spec (as specified by the CASE 1.1 spec). The v1p1 API URLs will have this format:<nobr><code>https://example.edu/ims/case/<b>v1p1</b>/CFPackages/XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX</code></nobr></li>
			<li>The v1p0 and v1p1 APIs will leave out items that appear in <span v-html="link('supplemental_info', '“supplemental branches”')"></span> of the framework tree.
				Use the “vext” version of the API to include these supplemental branches in the CASE JSON. The vext API URLs will have this format:<nobr><code>https://example.edu/ims/case/<b>vext</b>/CFPackages/XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX</code></nobr></li>
		</ul></li>
	</ul>
	<p>The full list of available CASE APIs are as follows (see the official <a href="https://www.imsglobal.org/sites/default/files/CASE/casev1p0/rest_binding/caseservicev1p0_restbindv1p0.html" target="_blank">CASE spec</a> for more details).</p>
	<table class="k-help-api-table">
		<tr><th>API Label</th>	<th>REST Endpoint</th> <th>Function</th></tr>
		<tr><td><code>getCFPackage</code></td>	<td><code>/CFPackages/{CASE GUID}</code></td> <td>Pull all data (CFDocument, CFItems, CFAssociations, etc.) for a framework. This is the most commonly-accessed API; use it to retrieve all the data you should need about a framework.</td></tr>
		<tr><td><code>getAllCFDocuments</code></td>	<td><code>/CFDocuments</code></td> <td>Pull the CFDocument JSON for all public frameworks available on the <span v-html="app_name"/> server. Use this to retrieve an “index” of frameworks you may be interested in getting more information about.</td></tr>
		<tr><td><code>getCFDocument</code></td>	<td><code>/CFDocuments/{CASE GUID}</code></td> <td>Pull the full CFDocument JSON for the specified document/framework.</td></tr>
		<tr><td><code>getCFItem</code></td>	<td><code>/CFItems/{CASE GUID}</code></td> <td>Pull the full CFItem JSON for the specified item (e.g. a learning standard).</td></tr>
		<tr><td><code>getCFAssociation</code></td>	<td><code>/CFAssociations/{CASE GUID}</code></td> <td>Pull the full CFAssociation JSON for the specified entity.</td></tr>
		<tr><td><code>getCFItemAssociations</code></td>	<td><code>/CFItemAssociations/{CASE GUID}</code></td> <td>Pull the full CFItemAssociation JSON for all associations involving the specified CFItem.</td></tr>
		<tr><td><code>getCFAssociationGrouping</code></td>	<td><code>/CFAssociationGroupings/{CASE GUID}</code></td> <td>Pull the full CFAssociationGrouping JSON for the specified entity.</td></tr>
		<tr><td><code>getCFConcept</code></td>	<td><code>/CFConcepts/{CASE GUID}</code></td> <td>Pull the full CFConcept JSON for the specified entity.</td></tr>
		<tr><td><code>getCFItemType</code></td>	<td><code>/CFItemTypes/{CASE GUID}</code></td> <td>Pull the full CFItemType JSON for the specified entity.</td></tr>
		<tr><td><code>getCFLicense</code></td>	<td><code>/CFLicenses/{CASE GUID}</code></td> <td>Pull the full CFLicense JSON for the specified entity.</td></tr>
		<tr><td><code>getCFRubric</code></td>	<td><code>/CFRubrics/{CASE GUID}</code></td> <td>Pull the full CFRubric JSON for the specified entity.</td></tr>
		<tr><td><code>getCFSubject</code></td>	<td><code>/CFSubjects/{CASE GUID}</code></td> <td>Pull the full CFSubject JSON for the specified entity.</td></tr>
	</table>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
.k-help-api-table {
	border-collapse:collapse;
	th, td {
		border:1px solid #ccc;
		padding:2px 4px;
		vertical-align:top;
		font-size:12px;
		line-height:16px;
	}
}
</style>
	